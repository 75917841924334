<template>
  <div class="add-fertilizer">
    <HeaderNavigation class="mb-12" type="book"></HeaderNavigation>
    <Form ref="form" :rows="rows" v-model="detail"> </Form>
    <div class="image-viewer-wrap mb-12">
      <div class="title required">订货明细</div>
      <ImageViewer ref="imageViewer" :upload="true" :limit="1" />
    </div>
    <FooterButton :loading="loading" @click="submit">保存</FooterButton>
  </div>
</template>
<script>
import HeaderNavigation from "@/components/header/headerWithNavigation";
import FooterButton from "@/components/common/button/FooterButton.vue";
import Form from "@/components/common/form/index.vue";
import ImageViewer from "@/components/common/imageViewer";
import { agriculturalTechnologyApi } from "@/utils/api.js";
export default {
  components: {
    HeaderNavigation,
    FooterButton,
    Form,
    ImageViewer,
  },
  data() {
    return {
      loading: false,
      id: "",
      detail: {},
      rows: [
        {
          key: "type",
          label: "农技会类型",
          gap: true,
        }, // 只显示
        {
          key: "personNum",
          label: "参会人数",
          unit: "人",
          type: "input",
          inputType: "number",
          required: true,
        },
        {
          key: "orderNum",
          label: "订肥量",
          unit: "吨",
          type: "input",
          inputType: "number",
          required: true,
        },
      ],
    };
  },
  activated() {
    this.detail = {};
    this.id = this.$route.params.id;
    this.$set(this.detail, "type", this.$route.query.type || "");
  },
  methods: {
    async submit() {
      if (!this.$refs.form.validate()) {
        return;
      }
      const orderPhotos = this.$refs.imageViewer.tempfiles;
      if (!orderPhotos || !orderPhotos.length) {
        this.$toast("请添加订货明细");
        return;
      }
      const { personNum, orderNum } = this.detail;
      this.loading = true;
      try {
        if (this.$route.query.type === "史丹利农技会") {
          await agriculturalTechnologyApi.addFertilizer({
            orderPhoto: (orderPhotos || [])
              .map((item) => item.uploadUrl)
              .join(","),
            kjTechconference: this.id,
            personNum,
            orderNum,
          });
        } else {
          await agriculturalTechnologyApi.distAddFertilizer({
            orderPhoto: (orderPhotos || [])
              .map((item) => item.uploadUrl)
              .join(","),
            kjTechconference: this.id,
            personNum,
            orderNum,
          });
        }
        this.$toast("新增成功");
        this.$router.back();
      } catch (err) {
        this.$toast(err.message);
      }
      this.loading = false;
    },
  },
};
</script>
<style lang="scss" scoped>
.add-fertilizer {
  width: 100%;
  min-height: 100vh;
  background-color: #fafbfc;
  .image-viewer-wrap {
    padding: 10px 24px 18px;
    text-align: left;
    background-color: #ffffff;
    .title {
      font-size: 14px;
      color: #616266;
      margin-bottom: 12px;
      &.required {
        &::before {
          content: "*";
          margin-right: 4px;
          color: #cd001f;
        }
      }
    }
  }
}
</style>
