<template>
  <div class="agricultural-technology-detail">
    <Header class="mb-12" />
    <Form ref="form" :rows="rows.slice(0, 6)" v-model="detail" />
    <div class="image-viewer-wrap mb-12">
      <div class="title">现场拍照</div>
      <ImageViewer
        :upload="false"
        :images="(detail.personPhoto || '').split(',')"
      />
    </div>
    <div class="image-viewer-wrap mb-12">
      <div class="title">会议现场拍照</div>
      <ImageViewer
        :upload="false"
        :images="(detail.livePhoto || '').split(',')"
      />
    </div>
    <Form ref="form" :rows="rows.slice(6)" v-model="detail" />
    <div class="image-viewer-wrap mb-12">
      <div class="title">订货明细</div>
      <ImageViewer
        :upload="false"
        :images="(detail.orderPhoto || '').split(',')"
      />
    </div>
    <FooterButton
      v-if="activeName !== 'AgriculturalTechnologyShidanliDetail'"
      :loading="loading"
      @click="handleDelete"
    >
      删除
    </FooterButton>
  </div>
</template>
<script>
import Header from "@/components/header/index";
import Form from "@/components/common/form/index.vue";
import ImageViewer from "@/components/common/imageViewer";
import { agriculturalTechnologyApi } from "@/utils/api.js";
import FooterButton from "@/components/common/button/FooterButton";
import { Dialog } from "vant";
export default {
  components: {
    Header,
    Form,
    ImageViewer,
    FooterButton,
  },
  data() {
    return {
      loading: false,
      id: "",
      activeName: "",
      detail: {},
    };
  },
  activated() {
    this.activeName = this.$route.name;
    this.id = this.$route.params.id;
    this.fetchDetail();
  },
  computed: {
    rows() {
      return [
        { key: "techconferenceType", label: "农技会类型" },
        { key: "kjDealerName", label: "所属经销商" },
        // {
        //   key: "kjBigfarmersName",
        //   label: "所属种植大户",
        //   gap: true,
        //   hide: this.detail.techconferenceTypeValue !== "1",
        // },
        {
          key: "kjTerminalNodeName",
          label: "所属终端",
          gap: true,
          hide: ["1", "3"].includes(this.detail.techconferenceTypeValue),
        },
        {
          key: "kjModelFieldName",
          label: "所属示范田",
          gap: true,
          hide: this.detail.techconferenceTypeValue !== "3",
        },
        { key: "techconferenceAddress", label: "会议召开地点" },
        { key: "holdTime", label: "会议召开时间", gap: true },
        { key: "personNum", label: "参会人数", unit: "人" },
        { key: "orderNum", label: "订肥量", unit: "吨" },
        { key: "orderDate", label: "订肥时间", gap: true },
      ];
    },
  },
  methods: {
    async fetchDetail() {
      let response = "";
      if (this.activeName === "AgriculturalTechnologyShidanliDetail") {
        response = await agriculturalTechnologyApi.detail({
          id: this.id,
        });
      } else {
        response = await agriculturalTechnologyApi.distDetail({
          id: this.id,
        });
      }
      this.detail = response.data;
    },
    async handleDelete() {
      try {
        await Dialog.confirm({
          title: "提示",
          message: "确定要删掉这条数据吗?",
        });
        this.confirmDelete();
      } catch (err) {
        //
      }
    },
    async confirmDelete() {
      try {
        await agriculturalTechnologyApi.distDelete({ id: this.id });
        this.$toast("删除成功");
        this.$router.back();
      } catch (err) {
        this.$toast(err.message);
      }
    },
  },
};
</script>
<style lang="scss" scoped>
.agricultural-technology-detail {
  width: 100%;
  min-height: 100vh;
  background-color: #fafbfc;
  padding-bottom: 56px;
  .image-viewer-wrap {
    padding: 10px 24px 18px;
    text-align: left;
    background-color: #ffffff;
    .title {
      font-size: 14px;
      color: #616266;
      margin-bottom: 12px;
    }
  }
}
</style>
