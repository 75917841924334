<template>
  <div class="filter-page">
    <Header class="mb-12" />
    <Form ref="form" :rows="rows" v-model="detail" />
    <FooterButton
      cancelLabel="重置"
      confirmLabel="确认"
      @cancel="reset"
      @confirm="confirm"
    />
  </div>
</template>
<script>
import Header from "@/components/header";
import FooterButton from "@/components/common/button/FooterDoubleButton.vue";
import Form from "@/components/common/form/index.vue";
import dayjs from "dayjs";
export default {
  components: {
    FooterButton,
    Header,
    Form,
  },
  data() {
    return {
      rows: [
        { key: "holdTime1", label: "开始时间", type: "date" },
        { key: "holdTime2", label: "结束时间", type: "date", gap: true },
        { key: "personNum", label: "参会人数", type: "range", unit: "人" },
        { key: "orderNum", label: "订肥数量", type: "range", unit: "吨" },
      ],
      detail: {},
    };
  },
  props: {
    value: {
      type: Object,
      default: () => {
        return {};
      },
    },
  },
  methods: {
    reset() {
      this.detail = {};
      // this.$emit("input", {});
    },
    confirm() {
      const { holdTime1, holdTime2, personNum, orderNum } = this.detail;
      const query = {
        holdTime1: holdTime1 ? dayjs(holdTime1).format("YYYY-MM-DD") : "",
        holdTime2: holdTime2 ? dayjs(holdTime2).format("YYYY-MM-DD") : "",
        personNum1: personNum ? personNum[0] : "",
        personNum2: personNum ? personNum[1] : "",
        orderNum1: orderNum ? orderNum[0] : "",
        orderNum2: orderNum ? orderNum[1] : "",
      };
      // this.$emit("input", query);
      // this.$emit("confirm");
      window.localStorage.setItem(
        "shidanli-filter",
        JSON.stringify(query || {})
      );
      this.$router.push({ path: "/agriculturalTechnology" });
    },
  },
};
</script>
<style lang="scss" scoped>
.filter-page {
  width: 100%;
  min-height: 100vh;
  background-color: #fafbfc;
}
</style>
