<template>
  <div class="agricultural-technology-add">
    <HeaderNavigation class="mb-12" type="book"></HeaderNavigation>
    <Form ref="form" :rows="rows" v-model="detail"> </Form>
    <div class="image-viewer-wrap mb-12">
      <div class="title"><span class="required">*</span>现场拍照</div>
      <ImageViewer ref="imageViewer" :upload="true" :limit="4" />
      <div class="tip">所有参与人员</div>
      <ImageViewer ref="imageViewer2" :upload="true" :limit="4" />
      <div class="tip">会议现场和客户合影</div>
    </div>
    <FooterButton :loading="loading" @click="submit">保存</FooterButton>
  </div>
</template>
<script>
import HeaderNavigation from "@/components/header/headerWithNavigation";
import FooterButton from "@/components/common/button/FooterButton.vue";
import Form from "@/components/common/form/index.vue";
import ImageViewer from "@/components/common/imageViewer";
import { agriculturalTechnologyApi, bigGrowersApi } from "@/utils/api.js";
import dayjs from "dayjs";
import { getDealerId } from "@/utils/user.js";
// import * as util from "@/utils/util.js";
export default {
  components: {
    HeaderNavigation,
    FooterButton,
    Form,
    ImageViewer,
  },
  data() {
    return {
      loading: false,
      detail: { holdTime: new Date() },
    };
  },
  computed: {
    rows() {
      return [
        {
          key: "techconferenceType",
          label: "农技会类型",
          required: true,
          type: "picker",
          dictType: "dealer_tech_type",
          // eslint-disable-next-line no-unused-vars
          handleChange: (value) => {
            this.$set(this.detail, "kjBigfarmers", "");
            this.$set(this.detail, "kjTerminalNode", "");
            this.$set(this.detail, "kjModelField", "");
          },
        },
        // {
        //   key: "kjBigfarmers",
        //   label: "所属种植大户",
        //   required: true,
        //   type: "picker",
        //   dictType: "grower",
        //   hide: this.detail.techconferenceType !== "1",
        // },
        {
          key: "kjTerminalNode",
          label: "所属终端",
          required: this.detail.techconferenceType === "2",
          type: "picker",
          dictType: "terminal",
          hide: ["1", "3", "", null, undefined].includes(
            this.detail.techconferenceType
          ),
        },
        {
          key: "kjModelField",
          label: "所属示范田",
          required: true,
          type: "picker",
          dictType: "modelField",
          hide: this.detail.techconferenceType !== "3",
        },
        {
          key: "techconferenceAddress",
          label: "会议召开地点",
          required: true,
          type: "input",
        },
        {
          key: "holdTime",
          label: "会议召开时间",
          type: "dateTime",
          gap: true,
          disabled: true,
        },
      ];
    },
  },
  activated() {
    this.detail = { holdTime: new Date() };
  },
  methods: {
    async submit() {
      if (!this.$refs.form.validate()) {
        return;
      }
      const personPhotos = this.$refs.imageViewer.tempfiles;
      const livePhotos = this.$refs.imageViewer2.tempfiles;
      if (
        !livePhotos ||
        !livePhotos.length ||
        !personPhotos ||
        !personPhotos.length
      ) {
        this.$toast("请上传现场照片");
        return;
      }
      this.loading = true;
      const {
        techconferenceType,
        techconferenceAddress,
        kjModelField,
        kjTerminalNode,
        kjBigfarmers,
      } = this.detail;
      let kjBigfarmersName = "";
      try {
        // 如果是种植大户，调用接口获取种植大户名字
        if (kjBigfarmers) {
          const res = await bigGrowersApi.distDetail({ id: kjBigfarmers });
          kjBigfarmersName = res.data.bigfarmersPerson;
        }
        await agriculturalTechnologyApi.distSave({
          managerPhoto: "",
          techconferenceName: "",
          //
          kjTerminalNode: kjTerminalNode || "", // 终端
          kjBigfarmers: kjBigfarmers || "", // 种植大户
          kjBigfarmersName: kjBigfarmersName || "", // 种植大户名称
          kjModelField: kjModelField || "", // 示范田
          techconferenceType: techconferenceType || "",
          techconferenceAddress: techconferenceAddress || "",
          kjDealer: getDealerId(),
          personPhoto: (personPhotos || [])
            .map((item) => item.uploadUrl)
            .join(","), // 现场拍照-参与人员
          livePhoto: (livePhotos || []).map((item) => item.uploadUrl).join(","), // 现场拍照-客户合影
          holdTime: dayjs(this.detail.holdTime).format("YYYY-MM-DD HH:mm:ss"),
        });
        this.$toast("新增成功");
        this.$router.back();
      } catch (err) {
        this.$toast(err.message);
      }
      this.loading = false;
    },
  },
};
</script>
<style lang="scss" scoped>
.agricultural-technology-add {
  width: 100%;
  min-height: 100vh;
  background-color: #fafbfc;
  .image-viewer-wrap {
    padding: 10px 24px 18px;
    text-align: left;
    background-color: #ffffff;
    .title {
      font-size: 14px;
      color: #616266;
      margin-bottom: 12px;
      .required {
        color: #cd001f;
        margin-right: 4px;
      }
    }
    .tip {
      font-size: 12px;
      color: #666666;
      margin-bottom: 8px;
    }
  }
}
</style>
